var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.tyre ? _c('ModelEditor', {
    attrs: {
      "title": _vm.title,
      "model": _vm.tyre,
      "errors": _vm.errors,
      "loading": _vm.$apollo.loading,
      "canSave": _vm.isDirty,
      "canCancel": _vm.isDirty
    },
    on: {
      "save": _vm.save,
      "cancel": _vm.restore
    }
  }, [_vm.$apollo.queries.tyre.loading ? _c('TalpaLoaderWrapper') : _vm.tyre ? _c('TyreStyled', [_c('AutoForm', {
    attrs: {
      "model": _vm.tyre,
      "modelName": 'Tyre',
      "filterFields": _vm.filterFields
    },
    on: {
      "update:model": function updateModel($event) {
        _vm.tyre = $event;
      }
    }
  }), _c('TyreThresholds', {
    attrs: {
      "mode": 'update',
      "thresholds": _vm.thresholds
    },
    on: {
      "update:thresholds": function updateThresholds($event) {
        _vm.thresholds = $event;
      }
    }
  })], 1) : _c('span', [_vm._v("error loading tyre")])], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }