<template>
  <ModelEditor
    v-if="tyre"
    :title="title"
    :model="tyre"
    :errors="errors"
    :loading="$apollo.loading"
    :canSave="isDirty"
    :canCancel="isDirty"
    @save="save"
    @cancel="restore"
  >
    <TalpaLoaderWrapper v-if="$apollo.queries.tyre.loading" />
    <TyreStyled v-else-if="tyre">
      <AutoForm :model.sync="tyre" :modelName="'Tyre'" :filterFields="filterFields" />
      <TyreThresholds :mode="'update'" :thresholds.sync="thresholds" />
    </TyreStyled>
    <span v-else>error loading tyre</span>
  </ModelEditor>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { isEqual, cloneDeep } from 'lodash'
import ModelEditor from '@/components/misc/ModelEditor'
import { AutoForm } from '@common/components'
import { TalpaLoaderWrapper } from '@common/components'
import TyreThresholds from './TyreThresholds'
import { FlashMessages } from '@common/singletons'

import TYRE_QUERY from '#/graphql/tyres/show.gql'
import TYRE_UPDATE from '#/graphql/tyres/update.gql'

const TyreStyled = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: minmax(3rem, min-content);
  grid-gap: 1rem;
`

export default {
  components: {
    TyreStyled,
    ModelEditor,
    AutoForm,
    TyreThresholds,
    TalpaLoaderWrapper,
  },
  data() {
    return {
      errors: [],
      tyre: null,
      tyreLocal: null,
      thresholdsToUpdate: null,
      enableSave: false,
      thresholdsInDatabase: [],
      thresholds: [],
      disabledFields: ['createdAt', 'updatedAt'],
      filterFields: [],
    }
  },
  computed: {
    title() {
      return `Tyre ${this.tyre?.serialNumber ?? ''}`
    },
    tyreId() {
      return this.tyre?.id ?? null
    },
    dirtyThresholds() {
      return !isEqual(this.thresholds, this.thresholdsInDatabase)
    },
    isDirty() {
      return !isEqual(this.tyre, this.tyreLocal) || this.dirtyThresholds
    },
  },
  methods: {
    editThresholds(thresholds, enableSave) {
      this.thresholdsChanged = thresholds
      this.enableSave = enableSave
    },
    async save() {
      const data = {
        serialNumber: {
          set: this.tyre.serialNumber,
        },
        model: {
          set: this.tyre.model,
        },
        isEndOfLife: {
          set: this.tyre.isEndOfLife,
        },
        thresholds: {},
      }

      data.thresholds.createMany = {
        data: this.thresholds.filter(t => !t.id),
      }

      data.thresholds.updateMany = this.thresholds
        .filter(t => t.id)
        .map(t => ({
          data: {
            // TODO: remove after deprecation phase
            threshold: {
              set: t.threshold,
            },
            min: {
              set: t.min,
            },
            max: {
              set: t.max,
            },
          },
          where: {
            id: {
              equals: t.id,
            },
          },
        }))
      try {
        const res = await this.$apollo.mutate({
          mutation: TYRE_UPDATE,
          variables: {
            where: {
              id: this.$route.params.id,
            },
            data,
          },
        })
        FlashMessages.$emit('success', `Tyre ${res.data.updateTyre.serialNumber} saved successfully`, {
          timeout: 3000,
        })
      } catch (err) {
        FlashMessages.$emit('error', err)
      }
    },
    async restore() {
      this.tyre = this.tyreLocal
      this.thresholds = this.thresholdsInDatabase
    },
  },
  apollo: {
    tyre: {
      query: TYRE_QUERY,
      variables() {
        return {
          where: {
            id: this.$route.params.id,
          },
        }
      },
      result({ data }) {
        this.tyre = data?.tyre
        this.tyreLocal = cloneDeep(data?.tyre)
        this.thresholds = data?.tyre?.thresholds
        this.thresholdsInDatabase = cloneDeep(data?.tyre?.thresholds)
      },
    },
  },
}
</script>
